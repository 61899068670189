@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
* {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  letter-spacing: 0;
  font-style: normal;
}

html,
body {
  height: 100%;
  margin: 0;
}

.backgroundBlue {
  background-color: #01457c;
}

.colorWhite {
  color: white;
}

.navbar-nav button {
  margin-left: 10px;
}

.nav-link:hover {
  color: white;
}

.nav-link.active {
  color: #01457c !important;
  font-weight: bold;
}

.btn.active {
  background: #01457c !important;
}

.mr-auto {
  margin-right: auto !important;
}

.buyOnAmazon,
.buyOnAmazon:hover,
.buyOnAmazon:active,
.buyOnAmazon:focus {
  background-color: #ff9900;
  border-color: #ff9900;
}

.headerIcon {
  display: flex;
}

.cartHeaderIcon {
  height: 30px;
  width: 30px;
}

.cartHeaderIconText {
  border-radius: 9px;
  font-size: 12px;
  background: #0d6efd;
  color: #fff;
  padding: 0 5px;
  vertical-align: top;
  margin-left: -10px;
  margin-top: -5px;
  height: -webkit-fill-available;
}

.accountHeaderIcon {
  height: 30px;
  width: 25px;
}

.closeButton {
  background-color: #4c5e77;
  border-color: #4c5e77;
}

.modalAlt {
  color: #0d6efd;
  cursor: pointer;
  margin: 0 auto 0 8px;
}

.logoText {
  color: #0c0c0c;
  text-decoration: none;
  font-size: 32px;
}

.carousel-item > img {
  max-height: 700px;
}

.homePageHeaderSection {
  margin-top: 40px;
  padding-bottom: 50px;
}

.homePageHeaderTitle {
  margin: 40px auto;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-size: 32px !important;
}

.homePageHeaderSectionText {
  margin: auto;
}

.homePageHeaderSectionImage {
  max-height: 600px;
}

.homePageHeaderSectionText > div {
  font-size: 18px;
}

.homePageHeaderSectionText > div:not(:first-child) {
  margin-top: 10px;
}

.homePageHeaderSectionText > div:last-child {
  height: 50px;
  width: 50%;
  margin: 40px auto;
}

.modal-content {
  max-width: 450px;
  margin: auto;
}

.modal-body > div:not(:first-child) {
  margin-top: 15px;
}

.modal-body > div:last-child {
  margin-bottom: 10px;
}

.modal-body > div > input {
  width: -webkit-fill-available;
}

.productPage {
  margin-top: 40px;
}

.productName {
  font-size: 24px;
  font-weight: 500;
}

.productPrice {
  font-size: 20px;
}

.productDescription {
  margin-top: 20px;
}

.productDescription > div {
  margin-top: 10px;
}

.productCart {
  margin-top: 20px;
  display: flex;
}

.productCart div {
  line-height: 30px;
}

.productCart > div:not(:first-child) {
  margin-left: 20px;
}

.quanityValue {
  display: flex;
  border-radius: 5px;
  background-color: #4c5e77;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.quanityValue > div {
  width: 40px;
  height: 30px;
  color: #fff;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.quantityChange {
  cursor: pointer;
}

.addToCart {
  cursor: pointer;
  background-color: #0d6efd;
  border-radius: 5px;
  padding: 0 20px;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.cartTitle {
  margin: auto;
  margin-top: 10px;
  text-align: center;
  font-size: 32px;
  background-color: gray;
  color: #fff;
  justify-content: center;
  border-radius: 5px;
  padding: 10px;
}

.noItems {
  margin: auto;
  margin-top: 30px;
  font-size: 20px;
}

.cartItem {
  margin-top: 20px;
  border: 1px solid gray;
  border-radius: 5px;
  background-color: ghostwhite;
  padding: 20px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cartImage {
  height: 60px;
}

.cartItemLeftSide {
  display: flex;
}

.cartItemLeftSide > div > div:first-child {
  font-size: 24px;
}

.cartItemLeftSide > div > div {
  margin: auto 20px;
}

.quanityValue div {
  line-height: 30px;
}

.cartItemRightSide > div:last-child {
  text-align: center;
  font-size: 12px;
  color: red;
  cursor: pointer;
}

.cartGoToCheckout {
  margin-top: 20px;
}

.cartGoToCheckoutButton {
  margin-top: 8px;
  color: #fff;
  background-color: #01457c;
  border-radius: 5px;
  width: 100px;
  height: 40px;
  font-size: 20px;
  line-height: 38px;
  text-align: center;
  cursor: pointer;
}

